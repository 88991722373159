/* ==========================================================================
   About Page
   ========================================================================== */

@media screen and (min-width: 1280px) {

  .page-template-template-about .container {
    width: 1280px;
  }

}


.text-block {
    background: $light-yellow;
    padding: 94px 0;
    margin-bottom: 10px;
}

@media screen and (max-width: $bp-max-xs) {

  .text-block {
      background: #E6E7E8;
      padding: 64px 30px;
      margin-bottom: 10px;
  }

}

.text-block-title {
    font-family: $als-medium;

    @media screen and (max-width: $bp-max-xs) {
       font-size: 24px;
       margin-bottom: 25px;
   }

    @media screen and (min-width: $bp-min-sm) {
       font-size: 30px;
       margin-bottom: 45px;
    }


    color: black;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
}

.text-block-subtitle {
    font-family: $als-serif;

    @media screen and (max-width: $bp-max-xs) {
       font-size: 18px;
       margin-bottom: 20px;
       line-height: 1.4;

    }

    @media screen and (min-width: $bp-min-sm) {
      font-size: 24px;
      margin: 0 auto 35px;


    }

    font-style: italic;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}

.text-block-text {
    font-family: $als-serif;

    @media screen and (max-width: $bp-max-xs) {
     font-size: 14px;

    p {
        margin-bottom: 15px;
    }

    }

    @media screen and (min-width: $bp-min-sm) {
      font-size: 16px;

      p {
          margin-bottom: 30px;
      }

    }
}

.content-image {
    min-height:386px;
    margin-bottom: 10px;
    background-size: cover;
    background-position: center center;
    .container {
      height: 386px;
      line-height: 386px;
        img {
          display: inline-block;
        }
      }

}

.content-image-caption-img {
  margin: 0 auto;
}


@media screen and (min-width: $bp-min-xl) {
    .content-image {
      min-height:500px;
    .container {
      height: 500px;
      line-height: 500px;
        img {
          display: inline-block;
        }
      }
    }
}

.column-1 {
    max-width: 662px;
    margin: 0 auto;
}


.columns-2 {
    column-count: 2;
    column-gap: 30px;

 p {
    text-align: left;
    margin: 0 auto;
    padding-bottom: 30px;
    page-break-inside: avoid;           /* Theoretically FF 20+ */
    break-inside: avoid-column;         /* IE 11 */
  }
}


@media screen and (max-width: $bp-max-xs) {
.columns-2 {
    column-count: 1;
}

}

.content-image-caption {
    margin-bottom: 0;
    background: #8ecddc;
    padding-top: 54px;
    padding-bottom: 68px;

    h3 {
        font-size: 18px;
        max-width: 648px;
        margin-top: 0;
        margin-left: auto;
        margin-right: auto;
    }

    @media screen and (max-width: $bp-max-xs) {
      padding-left: 20px;
      padding-right: 20px;
      h3 {
        font-size: 14px;
      }
    }


    @media screen and (min-width: $bp-min-sm) and (max-width: $bp-max-md) {
      padding-left: 20px;
      padding-right: 20px;
      h3 {
        font-size: 21px;
      }
    }

}

/* ==========================================================================
   Contact Page
   ========================================================================== */

.contact-wrap {
  background: #F1F1F2;
  @media screen and (max-width: $bp-max-xs) {
    padding: 100px 30px;
  }
  @media screen and (min-width: $bp-min-sm) {
    padding: 100px 0;
  }
  @media screen and (max-width: $bp-max-xs) {
    padding: 64px 30px;
  }
  margin-bottom: 10px;
  h2 {
    font-size: 24px;
    font-family: $als-medium;
    text-transform: uppercase;
    max-width: 300px;
    margin-bottom: 20px;
  }
  p {
   font-size: 14px;
   font-family: $als-medium;
  }
  p + p {
   font-size: 14px;
   font-family: $als-serif;
  }

  @media screen and (max-width: $bp-max-xl) {
    .pull-left, .pull-right {
      float: none!important;
    }
  }


}

.contact-footer {
    background-size: cover;
    background-position: center;
    min-height: 400px;
    margin-bottom: 10px;
}

#contact-form div.pull-left {
    margin-right: 10px;
}

#contact-form p {
    font-family: $als-regular;
}

input {
  height: 56px;
  padding-left: 26px;
  width: 100%;
  border: 1px solid #fff;

}

textarea {
  padding-left: 26px;
  padding-top: 20px;
  /*width: 354px;*/
  width: 100%;
  border: 1px solid #fff;
  height: 120px;
}

@media screen and (max-width: $bp-max-xxs) {
   /*textarea {
      width: 250px;
    }*/
}

input[type=submit] {
    background: #404041;
    transition: all 0.5s ease;
    /*width: 354px;*/
    width: 100%;
    text-align: center;
    font-size: 21px;
    color: #fff;
    text-transform: uppercase;
    margin-top: -5px;
    padding: 0;

    &:hover {
      background: #000;
    }
}

/*
@media screen and (max-width: $bp-max-xxs) {
   input[type=submit]  {
      width: 250px;
    }
}
*/
input:focus,
textarea:focus {
  border: 1px solid #ccc;
  outline: none;
}


div.wpcf7-response-output  {
  float: left;
  width: 38.5%;
  margin: 0;
}


@media screen and (max-width: $bp-max-xs) {
  div.wpcf7-response-output  {
    width: 98%;
  }
}

div.wpcf7-validation-errors {
  border: 1px solid red;
  font-family: $als-regular;
  padding: 10px;
  font-size: 14px;
  background: #fff;
}

@media screen and (max-width: $bp-max-sm) {

  .contact-content p {
    float: left;
    margin-right: 5%;
    min-height: 100px;
  }

  .contact-content p:first-of-type {
    float: none;
    margin-right: 0;
    min-height: 0;
  }
  .contact-content p:last-of-type {
    min-height: 0;
  }

}
@media screen and (max-width: $bp-max-xxs) {

  .contact-content p {
    float: none;
    margin-right: 0;
    min-height: 0;

  }
  .contact-wrap p+p {
    height: auto;
    min-height: 0;
  }

}

/* ==========================================================================
   Not Found
   ========================================================================== */

#notfound{
  background: #F1F1F1;
  padding-top: 12%;
  width: 100%;
  display: table;

  .img-container {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }

  img {
    max-width: 100%;
  }

  p {
    margin-top: 136px;
    margin-bottom: 50px;
    font-style: italic;
    font-size: 16px;
  }

}


.wrap {
  .page-template-template-contact & {
    min-height: 70vh;
  }
}

.text-block {
  background: #F1F1F1;

  h1 {
    font-size: 32px;
    font-family: $als-medium;
    text-transform: uppercase;
    margin-bottom: 0.8em;
  }

  h3,
  h2 {
    font-size: 24px;
    font-family: $als-medium;
    text-transform: uppercase;
    margin-bottom: 1em;
  }

  p {
    margin-bottom: 1.5em;
  }

  ul {
    margin-bottom: 1.5em;
  }
}