.home-banner {
  height: 500px;
  background-size: cover;
  background-position: center;

  @media screen and ( max-width: $bp-max-sm ) {
    height: 400px;
  }

  @media screen and ( max-width: $bp-max-xs ) {
    height: 320px;
  }

  img {
    margin: 0 auto;
    max-height: 100%;
  }

  &__content {
    position: relative;
    background: $light-yellow;
    padding: 10px;
    margin-bottom: 10px;

    @include media('>=sm')  {
      padding: 52px 0;
      margin-left: -5px;
      margin-right: -5px;
      margin-top: 10px;
    }

    .text-center {
      font-size: 18px;
    }

    p {
      max-width: 465px;
      margin: 0 auto;

      @include media('<md') {
        font-size: 16px;
        padding: 0 20px;
      }

      @include media('<sm') {
        font-size: 14px;
        max-width: 85%;
        margin: 0 auto;
        padding: 15px 0;
      }
    }
  }
}
