
/*Article Content*/

.article-post-image {
    height: 470px;
    background-color: #E6E7E8;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.article-content {
    background: #E6E7E8;

    @media screen and (max-width: $bp-max-sm)  {
        height: 470px;
        padding: 26px 20px;
    }

    @media screen and (min-width: $bp-min-md)  {
        height: 470px;
        padding: 66px 0;
    }     

   transition: all ease-in-out 0.5s;

    &:hover {
      background: #D3D3D3;
    }
}

.entry-date {
    font-family: $als-serif;
    font-style: italic;
    font-size: 14px;
    margin-bottom: 30px;
}

.entry-title {
    font-family: $als-medium;
    font-size: 18px;
    margin-bottom: 40px;
}

.entry-summary {
    font-size: 14px;
    line-height: 18px;
    max-width: 477px;

    @media screen and (max-width: $bp-max-sm)  {
    
      margin: 0 auto 30px;

    }

    @media screen and (min-width: $bp-min-md)  {
    
      margin: 0 auto 60px;

    }

    + img.leaf {
        width: 60px;
    }
}

.read-more {
    display: block;
    width: 135px;
    margin: 30px auto 0;
    padding: 10px 0;
    border: 2px solid #000;
    font-family: $als-medium;
    font-size: 12px;
    text-transform: uppercase;
    transition: all ease-in-out 0.3s;

    &:hover {
    border: 2px solid #000;
    background: #000;
     color: #fff;   
    }        

}

.separator {
    display: block;
    width: 140px;
    margin: 0 auto 30px;
    height: 1px;
    background: #000;
}

article {
    margin-bottom: 10px;
}

.single-post article {

    background: #E6E7E8;

    .entry-date {
        margin-bottom: 30px;
        font-family: $als-serif;
    }

    .entry-content {
        background: #fff;
        margin-top: 10px;
        padding: 0 0 30px;
        position: relative;
        font-family: $als-regular;
    }

    @media screen and (max-width: $bp-max-xs) {

        .entry-content {
            background: #fff;
            margin-top: 10px;
            padding: 0 30px;
            position: relative;
        }
    }

    img {
        max-width: 100%;
        height: auto;
    }

    .entry-meta {
        padding-top: 30px;
        margin-bottom: 30px;
        font-style: italic;
    }

    .entry-content h1, h2, h3 {
        font-size: 14px;
        font-family: $als-medium;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    .entry-content p {
        margin-bottom: 20px;
    }

    h1.entry-title{
        font-size: 21px;
        max-width: 420px; 
        margin: 0 auto 40px;
    }
}

.article-footer {
    padding-bottom: 30px;
    margin-top: 80px;
}

.next-post-link,
.prev-post-link {
    display: inline-block;
    font-family: $als-regular;
    font-size: 14px;
    transition: all ease-in-out 0.15s;
}

.next-post-link i {
    margin-left: 10px;
    font-size: 18px;
    transition: all ease-in-out 0.15s;

}

.prev-post-link i {
    margin-right: 10px;
    font-size: 18px;
    transition: all ease-in-out 0.15s;

}

.prev-post-link span,
.next-post-link span {
    display: inline-block;
    position: relative;
    top: -2px;
    transition: all 0.3s linear;
}

.next-post-link:hover span {
    transform: translateX(7px);
}

.prev-post-link:hover span {
    transform: translateX(-7px);
}

.article-back {
    font-size: 14px;
    font-family: $als-regular;
    display: inline;
    position: absolute;
    top: 30px;
    left: 40px;
    transition: all ease-in-out 0.3s;
}

.fa-long-arrow-left {
    transition: all ease-in-out 0.3s;
}

.article-back:hover .fa-long-arrow-left{
    transform: translateX(-7px);
}

.entry-content-inner {
    max-width: 715px;
    margin: 0 auto;
}