/* ==========================================================================
   Styles everything product specific
   ========================================================================== */

.fa-chevron-down,
.fa-chevron-up {
	transition: all linear 0.1s;
}

.rotate {
	transform: rotate(180deg);
}

.fa-angle-double-right {
	transition: all ease-in-out 0.5s;
	position: absolute;
	bottom: 5%;
	right: 40%;
	opacity: 0;
}


#als-product-nav ul li a {
  position:relative;
  display:inline-block;
  z-index: 1;
  padding: 10px 6px;
  width:100%;
}

#als-product-nav ul li a:after {
  content: "";
  display:inline-block;
  z-index: 2;
  position:absolute;
  left:0;
  top:45%;
  width:100%;
  border-top:2px solid white;
  transform: scale(0);
  transition: all 0.3s ease;
}

#als-product-nav ul li a:hover:after,
#als-product-nav ul li a.active:after {
  width:100%;
  z-index: 2;
  transform: scale(1);
}

html.no-csstransforms #als-product-nav ul li a:after {
	display: none;
}

html.no-csstransforms #als-product-nav ul li a:hover:after,
html.no-csstransforms #als-product-nav ul li a.active, {
	text-decoration: line-through!important;
}

#als-product-nav {
	background: #000;
	color: #fff;
	font-family: $als-regular;
  	text-align:center;
  	margin-bottom: -10px;

	ul { margin-bottom: 0; }

	ul li { display:inline-block; }

	ul li a {
		color: #fff;
		display: inline-block;
		padding: 10px;
		text-transform: uppercase;
		position: relative;
		z-index: 0;
		transition: all ease-in-out 0.9s;
	}

}

/* ---------------------
 * Product Main Wrap
 * --------------------- */

.als-product-main-wrap {
	position: relative;
	padding-bottom:75%;
	margin-top: 10px;
	display: block;
	overflow: hidden;
}

.product-headings {
	overflow: auto;
}


h2.als-product-title {
	font-family: $als-medium;
	font-size: 18px;
	position: absolute;
  top: 68%;
  width: 440px;
  margin: 0 auto;
  padding: 10% 0;
  line-height: 1.3;
	left: 50%;
	margin-left: -220px;
}

h3.als-product-subtitle-in {
	font-family: $als-serif;
	font-size: 16px;
	text-transform: none;
	font-style: italic;
  top: 74%;
  width: 350px;
  margin: 0 auto;
  padding: 10% 0;
  line-height: 1;
  left: 50%;
  margin-left: -175px;
  position: absolute;
  text-align: center;
}

@media screen and (max-width: $bp-max-sm) {

	// padding-bottom: 55%;

	h2.als-product-title {
		top: 55%;
	}

	h3.als-product-subtitle-in {
		font-size: 18px;
		width: 480px;
	  left: 50%;
	  margin-left: -240px;
		top: 73%;
	}

}

@media screen and (max-width: $bp-max-xs) {

	h2.als-product-title {
		top: 63%;
	}

	h3.als-product-subtitle-in {
		font-size: 12px;
		width: 280px;
	  left: 50%;
	  margin-left: -140px;
		top: 71%;
	}

}

.als-product-main-wrap.active .als-product-content {
	transform: translateX(0);
}

html.no-csstransforms .als-product-main-wrap.active .als-product-content  {
	display: none;
}

.als-product-main-wrap .als-product-title {
	opacity: 1;
	transition: opacity ease-in-out 0.5s;
}

.als-product-main-wrap.active .als-product-title {
	opacity: 0;
}

@media screen and (max-width: $bp-max-sm) {
	.als-product-main-wrap.active .als-product-title {
		opacity: 1;
	}
}

.als-product-main-wrap.active .als-product-image-wrap {
	transform: translateX(-38%);
}

html.no-csstransforms .als-product-main-wrap.active .als-product-image-wrap  {
	display: none;
}

.als-product-main-wrap.active .fa-angle-double-right {
	opacity: 1;
	transition: all ease-in-out 0.5s;
}

.als-product-main-wrap.active .als-product-wrap,
.als-product-main-wrap:hover .als-product-wrap {
	background: #efeee6;
}


/* ---------------------
 * Product Wrap
 * --------------------- */

.als-product-wrap {
	overflow: hidden;
	width: 100%;
	height: 100%;
	position: absolute;
	background: $light-yellow;
	transition: all ease-in-out 0.2s;

	&:hover {
		background: #ccc;
	}

	.als-product-footer {
		width: 100%;
		padding-right: 50px;
		position: absolute;
		bottom: 7px;
	}

	@media screen and (max-width: $bp-max-sm) {
		.als-product-footer {
			padding-right: 20px;
		}
	}

}




.als-product-link-wrap {
	cursor: pointer;
	overflow: hidden;
	height: 0;
}

/* ---------------------
 * Product Content
 * --------------------- */

html.no-csstransforms .als-product-content {
	display: none;
}
.als-product-content {
	transform: translateX(100%);
	transition: all ease-in-out 0.5s;
	padding: 10px 20px 10px 30px;
	max-width: 75%;
	background: rgb(255,255,255);
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	z-index: 1;

	.fa-times {
		position: absolute;
		top: 10px;
		right: 0;
		cursor: pointer;
		display: none;
	}

	h2 {
		font-family: $als-medium;
		font-weight: bold;
		font-size: 18px;
		margin-bottom: 20px;
    margin-top:30px;

    @media screen and (max-width: $bp-max-md) {
    	margin-top:10px;
    }
    @media screen and (max-width: $bp-max-sm) {
    	margin-top:10px;
    }

	}

	.als-product-desc {
		font-size: 14px;
		font-family: $als-serif;
		padding-top: 10px;
	}

	h3 {
		font-size: 12px;
		text-transform: uppercase;
		font-weight: bold;
		font-family: $als-medium;
	}

	h3.als-product-subtitle {
		font-family: $als-serif;
		font-size: 16px;
		margin-bottom: 15px;
		margin-top: -15px;
  	text-transform: none;
  	font-style: italic;
  	font-weight: normal;
	}

	span {
		font-family: $als-serif;
		display: block;
	}

	span span 	{
		font-family: $als-medium;
		display: inline;
	}

	span,	.als-product-howto  {
		font-size: 14px;
	}

	footer span {
		font-family: $als-medium;
	}

	.nano-content span {
		margin-top: 10px;
	}

	.nano-content span:first-child {
		margin-top: 0;
	}

}

.nano-content {
  padding-right: 15px;
}

.nano > .nano-pane > .nano-slider {
    background: rgba(0, 0, 0, 1) none repeat scroll 0 0;
    border-radius: 0;
    margin: 0 -2px;
    position: relative;
}

.nano > .nano-pane {
  background: rgba(0, 0, 0, 1) none repeat scroll 0 0;
  border-radius: 0;
  bottom: 0;
  opacity: 1;
  position: absolute;
  right: 2px;
  top: 5px;
  margin-bottom: 5px;
  transition: all 0.2s ease 0s;
  width: 2px;
}

.als-product-content.active {
	transform: translateX(0);
}

html.no-csstransforms .als-product-content.active {
	display: block;
}

/* ---------------------
 * Image Wrap
 * --------------------- */

.als-product-image-wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0;
  padding: 4.2% 3% 18.9% 3%;
	transform: translateX(0);
	transition: all ease-in-out 0.5s;
	margin: 0;
	text-align: center;

	img {
		  width: auto;
		  height: 100%;
	}
}

/* ---------------------
 * Accordion
 * --------------------- */

.panel {
	box-shadow: none;
	border: none;
}

.panel-body {
	height: 200px;
	padding: 10px 0 0;
}

@media screen and (max-width: $bp-max-xs) {
	.panel-body {
		height: 140px!important;
	}
}

.panel-heading {
	padding: 0;
	border-radius: 0;
}

.panel-group .panel {
	border-radius: 0;
}

.panel-default > .panel-heading {
	background: #fff;

	border-left: none;
	border-right: none;
}

.panel-default {
	border-top: 1px solid #000;
	border-bottom: 1px solid #000;
}

.panel-default + .panel-default {
	border-top: 0;
}

.panel-title {

	a {
		display: block;
		padding: 10px 0;
	}

	.fa-chevron-down,
	.fa-chevron-up {
		margin-top: -15px;
	}
}

.panel-group .panel + .panel {
	margin-top: 0;
}

.panel-group .panel-heading + .panel-collapse > .list-group, .panel-group .panel-heading + .panel-collapse > .panel-body {
	border-top: 1px solid #000;
}

@media screen and (max-width: $bp-max-sm) {
	.panel-group {
		margin-top: 10px;
	}
}

/* ---------------------
 * Various MD fixes
 * --------------------- */


@media screen and (max-width: $bp-max-xs) {

	.als-product-link-wrap {
	  cursor: pointer;
	  padding-bottom: 75%;
	  position: relative;
	  background: #E6E7E8;
	  transition: all ease-in-out 0.5s;
	}

	.als-product-main-wrap {
		padding: 0;
	}

	.als-product-content {
		transform: translateX(0);
		transition: all ease-in-out 0.5s;
		max-width: 100%;
		height: 0;
		position: static;
		padding: 10px;

	}

	.als-product-wrap {
		position: static;
		height: 0;
	}

	#als-product-nav {
		margin-bottom: 0;
	}

	.als-product-wrap h2.als-product-title {
		position: static;
		padding: 0;
	}

	.als-product-title-in, .als-product-subtitle  {display: none;}



	.als-product-main-wrap.active .als-product-content{
		transition: all ease-in-out 0.5s;
		transform: translateX(0);

		@media screen and (max-width: $bp-max-sm) {
			min-height: 300px;
		}

		@media screen and (min-width: $bp-min-md) {
			min-height: 400px;
		}
	}

	.als-product-main-wrap.active .als-product-wrap  {

		@media screen and (max-width: $bp-max-sm) {
			min-height: 300px;
		}

		@media screen and (min-width: $bp-min-md) {
			min-height: 400px;
		}

		transition: all ease-in-out 0.5s;
	}

	.als-product-main-wrap.active .als-product-image-wrap{
		transform: translateX(0);
	}

	h2.als-product-title {
		font-size: 16px;
		width: 400px;
		left: 50%;
		margin-left: -200px;
	}

	header .top-box {
		right: auto;
	}

}

@media screen and (max-width: $bp-max-xxs) {
		h2.als-product-title {
		font-size: 13px;
	}
}

html.no-csstransforms .als-product-main-wrap.active .als-product-content {
	display: block;
	max-width: 100%;
}
html.no-csstransforms .fa-angle-double-right {
	display: none;
}
html.no-csstransforms .fa-times {
	display: block;
}











