//Define the breakpoints at which your layout should change:
$screen-xl:                  1400px !default;
$screen-xl-min:              $screen-xl !default;
$screen-xl-desktop:          $screen-xl-min !default;

//Provide a new maximum, so media queries don't overlap when required:
$screen-lg-max:              ($screen-xl-min - 1) !default;

//Define a new maximum width of .container for different screen sizes. Keep in mind the + gutter width. You need to define the desired width minus the grid's gutter
$container-xlarge-desktop:      (1370px + $grid-gutter-width) !default;
$container-xl:                 $container-xlarge-desktop !default;

//Set the container width, and override it for fixed navbars in media queries.
.container {
  @include container-fixed;

  @media (min-width: $screen-xl-min) {
    width: $container-xl;
  }
}

//Create columns, offsets, pushes, and pulls for the your new size device range.
@media (min-width: $screen-xl-min) {
  @include make-grid(xl);
}

//Generate your new size columns:
@mixin make-xl-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  min-height: 1px;
  padding-left:  ($gutter / 2);
  padding-right: ($gutter / 2);
  @media (min-width: $screen-xl-min) {
    float: left;
    width: percentage(($columns / $grid-columns));
  }
}

@mixin make-xl-column-offset($columns) {
  @media (min-width: $screen-xl-min) {
    margin-left: percentage(($columns / $grid-columns));
  }
}
@mixin make-xl-column-push($columns) {
  @media (min-width: $screen-xl-min) {
    left: percentage(($columns / $grid-columns));
  }
}
@mixin make-xl-column-pull($columns) {
  @media (min-width: $screen-xl-min) {
    right: percentage(($columns / $grid-columns));
  }
}

//Add your new column sizes to the loop. The code below is the complete loop, with the xl size added to already.
@mixin make-grid-columns($i: 1, $list: ".col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}, .col-xl-#{$i}") {
  @for $i from (1 + 1) through $grid-columns {
    $list: "#{$list}, .col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}, .col-xl-#{$i}";
  }
  #{$list} {
    position: relative;
    // Prevent columns from collapsing when empty
    min-height: 1px;
    // Inner gutter via padding
    padding-left:  ($grid-gutter-width / 2);
    padding-right: ($grid-gutter-width / 2);
  }
}

//Add your new size to the Visibility utilities.
@include responsive-invisibility('.visible-xl');

.visible-xl-block,
.visible-xl-inline,
.visible-xl-inline-block {
  display: none !important;
}

@media (min-width: $screen-lg-max) {
  @include responsive-visibility('.visible-xl');
}
.visible-xl-block {
  @media (min-width: $screen-xl-min) {
    display: block !important;
  }
}
.visible-xl-inline {
  @media (min-width: $screen-xl-min) {
    display: inline !important;
  }
}
.visible-xl-inline-block {
  @media (min-width: $screen-xl-min) {
    display: inline-block !important;
  }
}

@media (min-width: $screen-xl-min) {
  @include responsive-invisibility('.hidden-xl');
}