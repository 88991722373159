header {
	background: #fff;
	/*display: table;*/
	width: 100%;
	/*z-index: 9999!important;*/
  position: relative;

  .header {
    height: 130px;
    font-family: $als-serif;
    font-size: 14px;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 50px;
  }

  .espa-banner {
    width: fit-content;
    // margin-bottom: 30px;
    margin: 0 auto;
    display: block;

		@media screen and (min-width: $bp-min-sm) and (max-width: $bp-min-xl) {
			margin-left: 4%;

      img {
        max-width: 85%;
      }
		}

		@media screen and (min-width: $bp-min-xl) {
      margin: 0 auto;
		}
  }

	.nav-wrapper {
	/*display:table-cell;
	vertical-align:middle;*/
	width: 100%;
	height: 60px;
	position: absolute;
	top: 50%;
	left: 0;
	margin-top: -30px;

    .row {
      display: flex;
      justify-content: center;
    }

    .navigation {
      width: fit-content;
    }
	}

	.row {
		margin-left: auto;
		margin-right: auto;
	}

	p.text-center {
		margin-bottom: 0;
	}

	nav ul li {
		padding-top: 30px;
		letter-spacing: 1px;
	}

	nav ul li:first-of-type {
		margin-right: 30px;
	}

	nav ul li:last-of-type {
		margin-right: 40px;

    @media screen and (min-width: $bp-min-lg) {
      margin-right: 85px;
		}
	}

	.right-menu nav ul li:first-of-type {
    margin-left: 45px;

    @media screen and (min-width: $bp-min-lg) {
      margin-left: 90px;
		}
	}

	.right-menu nav ul li:last-of-type {
		margin-right: 0;
	}


	.top-box {
		position: absolute;
		top: 0;
		z-index: 9999;

		@media screen and (max-width: $bp-max-md) {
			right: 4%;
		}

		@media screen and (min-width: $bp-min-lg) {
			right: 16.6%;
		}

		cursor: pointer;

		p {
			margin-bottom: 0;
		}

		a {
			color: #6D6E70;

		}

		.fb-like {
			background: #E6E7E8;
			// color: #6D6E70;
      color: #4d4d4d;
			margin-right: 7px;
			font-family: $als-medium;
			font-size: 11px;
			line-height: 1;
			transition: all ease-in-out 0.3s;
			padding: 20px 18px 15px;
		}

		.fb-like.active
		 {
			background: #000;
			color: #fff;
		}

		.fb-like.active a {
			color: #fff;
		}

		.fb-box {
			border-bottom: 1px solid;
			margin-bottom: 5px;
			padding-bottom: 10px;
			display: none;
		}
	}

}


nav ul li a {
    position:relative;
    padding: 0 7px;
    display:inline-block;
    z-index: 1;
}

nav ul li a:after {
	content: "";
	display:inline-block;
	z-index: 2;
	position:absolute;
	left:0;
	top:43%;
	width:100%;
	border-top:2px solid black;
	transform: scale(0);
	transition: all 0.3s ease;
}


nav ul li a:hover:after{
  width:100%;
  z-index: 2;
	transform: scale(1);
}

html.no-csstransforms nav ul li a:after  {
	display:none;
}
html.no-csstransforms nav ul li a:hover,
html.no-csstransforms .current-menu-item a  {
	text-decoration: line-through!important;
}


.current-menu-item a:after,
/*Add Class Active to the Products Menu Item*/
body.post-type-archive-products
.menu-primary-navigation-left-container li:last-of-type a:after,
body.post-type-archive-ingredients
.menu-primary-navigation-left-container li:last-of-type a:after
 {
    color: #A6A8AB;
    width:100%;
    z-index: 2;
	transform: scale(1);
}





.lang-menu {
  padding: 0;
  background: #E6E7E8;
  font-size: 11px;
  font-family: $als-medium;
  line-height: 1;
  transition: all 0.2s ease;

  li {
    position: relative;
    a {
      display: block;
      padding: 20px 18px 15px;
      transition: all 0.2s ease;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -13px;
        z-index: 1;
        width: 26px;
        height: 1px;
        background: white;
      }
    }
  }

  &-inner {
    background: #E6E7E8;
    position: absolute;
    list-style: none;
    top: 100%;
    padding: 0;
    width: 100%;
    height: 0;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease;

    a {
      color: white;
      padding-top: 19px;

      &:after {
        display: none;
      }

    }

  }

  &:hover {
    background: black;

     a {
      color: white;
     }

    .lang-menu-inner {
      background: black;

      height: 100%;
      visibility: visible;
      opacity: 1;

    }
  }

}


.mobile-menu-wrap  a {
  color: white!important;
  font-size: 11px;
  font-family: $als-regular;
}
