.als-ingredient-wrap {
	margin-top: 10px;
	min-height: 500px;
	position: relative;
	cursor: pointer;
	background-size: cover;
	background-position: center; 
}

.als-ingredient-title {
	padding: 37.5px 0 0;
	font-size: 18px;
	font-family: $als-medium; 
	transition: all 0.3s ease;
}

.als-ingredient-content {
	position: absolute;
	bottom: 0;
	left: auto;
	width: 100%;
	background: #000;
	color: #fff;

	p {
		display: none;
		font-size: 16px;
		line-height: 1.5;
		font-style: italic;
		padding:0 40px 40px;
		margin-top: -10px;
	}
}

.als-ingredient-wrap { 
	filter: grayscale(100%); 
	transition: all ease-in-out 0.5s;
	
	.i.fa.fa-angle-up {
		opacity: 0;
		transition: all 150ms ease;
		transform: rotateX(-180deg);		
	}


.fa-3x {
	font-size: 2em!important;
	margin-top: 10px;
}
	
}

html.touch .als-ingredient-wrap i.fa.fa-angle-up.fa-3x {
	opacity: 1;
}

html.touch .ingredient-visible-wrap {
	height: 110px;
}

.ingredient-visible-wrap {
	height: 95px;
	transition: all 0.3s ease;
}

.als-ingredient-wrap:hover,
.als-ingredient-wrap.active { filter: grayscale(0); }

.als-ingredient-wrap:hover .als-ingredient-title  {
	padding: 30px 0 0;
}

.als-ingredient-wrap:hover .ingredient-visible-wrap {
	height: 105px;
}

.als-ingredient-wrap i.fa.fa-angle-up.fa-3x {
	  opacity: 0;
	  transition: all 150ms ease;
}

.als-ingredient-wrap.active i.fa.fa-angle-up.fa-3x {
  transform: rotate(-180deg);
  transition: all 0.5s ease;
}


.als-ingredient-wrap:hover i.fa.fa-angle-up.fa-3x {
  opacity: 1;
}

@media screen and (max-width: $bp-max-xs) {
.als-ingredient-content p {
	padding: 0 20px 40px;
}
}
