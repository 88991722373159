#main-footer {
    background: #000;
    margin-top: 10px;
    text-align: center;
    padding-bottom: 10px;
    ul {
        margin-bottom: 0;
    }

    li {
        margin-right: 50px;
    }

    .right-menu li:first-of-type {
        margin-left: 50px;
    }

    li a {
        color: #fff;
        font-size: 14px;
        letter-spacing: 1px;
        text-transform: uppercase;
        padding-top: 30px;
        display: block;
    }

    p {
        margin-bottom: 0;
    }
}

#main-footer ul li a {
    position:relative;
    display:inline-block;
    z-index: 1;
}

#main-footer ul li a:after {
    content: "";
    display:inline-block;
    z-index: 2;
    position:absolute;
    left:0;
    top:77%;
    width:100%;
    border-top:2px solid white;
    transform: scale(0);
    transition: all 0.3s ease;
}

#main-footer ul li a:hover:after,
#main-footer ul li.current-menu-item a:after,
body.post-type-archive-products #main-footer ul li.menu-item-208 a:after,
body.post-type-archive-ingredients #main-footer ul li.menu-item-208 a:after
{
    width:100%;
    z-index: 2;
    transform: scale(1);
}

html.no-csstransforms #main-footer ul li a:after  {
    display:none;
}
html.no-csstransforms #main-footer ul li a:hover  {
    text-decoration: line-through!important;
}

.newsletter-block {
    margin: 1.4em auto;
    max-width: 510px;
    text-align: left;

    @include media("<sm") {
        padding: 0 25px;
    }

    &__copy {
        font-size: 12px;
        color: #F1F1F2;
        text-transform: uppercase;
        font-family: 'cf_asty_stdregular';
        margin-bottom: 0.8em;

        @include media("<sm") {
            text-align: center;
            font-size: 10px;
        }
    }
}

.mailchimp-form {
    position: relative;
    text-align: center;

    &__terms {
        position: relative;
        text-align: center;
        margin: 1em auto;

        input {
            position: absolute;
            z-index: -1;
            opacity: 0;

            &:checked {
                + label {
                    &::before {
                        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTYuNCwxTDUuNywxLjdMMi45LDQuNUwyLjEsMy43TDEuNCwzTDAsNC40bDAuNywwLjdsMS41LDEuNWwwLjcsMC43bDAuNy0wLjdsMy41LTMuNWwwLjctMC43TDYuNCwxTDYuNCwxeiINCgkvPg0KPC9zdmc+DQo=);
                        // border-color: black;
                    }
                    // &::after {
                    //     opacity: 1;
                    // }
                }
            }
        }

        label {
            font-family: 'cf_asty_stdregular';
            font-size: 10px;
            color: #BBBDBF;
            text-transform: uppercase;
            font-weight: normal;
            position: relative;
            line-height: 1.5;
            max-width: 250px;

            @include media(">=sm") {
                font-size: 11px;
                padding-left: 2em;
                max-width: none;
            }

            &:before {
                content: "";
                display: block;
                width: 12px;
                height: 12px;
                border: 1px solid #BBBDBF;
                position: absolute;
                left: 0;
                top: 2px;
                transition: all 0.2s ease-out;

                @include media(">=sm") {
                    top: 6px;
                    transform: translateY(-50%);
                }
            }

            // &:after {
            //     width: 14px;
            //     height: 14px;
            //     left: -4px;
            //     opacity: 0;
            // }

            &::before {
                background-size: 80% 80%;
                background-position: center center;
                background-repeat: no-repeat;
            }
        }

        a {
            color: #BBBDBF;
            transition: all 0.15s ease-out;

            &:hover {
                opacity: 0.7;
            }
        }
    }

    &__field {
        &--subscribe {
            margin: 2.2em auto 3.2em;

            @include media(">=sm") {
                display: inline-block;
                position: absolute;
                right: 0;
                top: 0;
                margin: 0;
            }

            a {
                transition: all 0.2s ease-out;

                &:hover {
                    color: black;
                }
            }
        }

        &--email {
          label {
            @extend .sr-only;
          }
        }
    }

    input[type=email] {
        color: #8E8E8E;
        width: 100%;
        height: 40px;
        padding: 8px 40px 6px;

        @include media(">=sm") {
            max-width: 357px;
            padding: 8px 12px 6px;
        }
    }

    &__responses {
        font-family: 'cf_asty_stdregular';
        font-size: 10px;
        color: #fff;
        text-transform: uppercase;
        font-weight: normal;
        // padding: 1em 0;
    }

    .mailchimp-subscribe {
        font-family: 'cf_asty_stdregular';
        font-size: 10px;
        color: #fff;
        text-transform: uppercase;
        font-weight: normal;
        padding: 0.9em 3.3em .8em;
        letter-spacing: 0.1em;

        @include media(">=sm") {
            font-size: 12px;
        }
    }
}

.footer-copyright {
    text-align: center;
    font-family: 'cf_asty_stdregular';
    font-size: 11px;
    color: #BBBDBF;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    padding: 2.5em 0 0.6em;

    a {
        color: #BBBDBF;
        transition: all 0.15s ease-out;
        &:hover {
            opacity: 0.7;
        }
    }
}
