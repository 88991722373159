@import "common/_variables";

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/_bootstrap.scss";
@import "../../bower_components/include-media/dist/_include-media.scss";
// endbower

@import "common/_global";
@import "common/_bootstrap-xl";
@import "components/_buttons";
@import "components/_mailchimp";
@import "components/_comments";
@import "components/_forms";
@import "components/_grid";
@import "components/_wp-classes";
@import "components/_home-banner";
@import "layouts/_header";
@import "layouts/_sidebar";
@import "layouts/_footer";
@import "layouts/_pages";
@import "layouts/_posts";

//Custom Imports
@import "common/_products";
@import "common/_ingredients";

.fa {
  font: normal normal normal 14px/1 FontAwesome !important;
}

