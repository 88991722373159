/*
 * Helper Classes
 */

.display-none {
  display: none;
}

/* ==========================================================================
   Fonts
   ========================================================================== */

@font-face {
    font-family: 'cf_asty_stdbold';
    src: url('../fonts/cfastystd-bold-webfont.eot');
    src: url('../fonts/cfastystd-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/cfastystd-bold-webfont.woff2') format('woff2'),
         url('../fonts/cfastystd-bold-webfont.woff') format('woff'),
         url('../fonts/cfastystd-bold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'cf_asty_stdregular';
    src: url('../fonts/cfastystd-book-webfont.eot');
    src: url('../fonts/cfastystd-book-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/cfastystd-book-webfont.woff2') format('woff2'),
         url('../fonts/cfastystd-book-webfont.woff') format('woff'),
         url('../fonts/cfastystd-book-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'cf_asty_stdmedium';
    src: url('../fonts/cfastystd-medium-webfont.eot');
    src: url('../fonts/cfastystd-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/cfastystd-medium-webfont.woff2') format('woff2'),
         url('../fonts/cfastystd-medium-webfont.woff') format('woff'),
         url('../fonts/cfastystd-medium-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

html {
  height: 100%;
}

body {
    font-family: $als-serif;
    overflow-x: hidden;
}

body.post-type-archive {
}

body.error404 {
  background: #F1F1F1;
}

body.error404 footer{
    border-top: 10px solid white;
}


h1, h2, h3, h4, h5 {
    margin: 0;
}

a {
    outline: 0!important;
    text-decoration: none!important;
}

a:hover {
	text-decoration: none;
}

.vmiddle {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.container,
.container-fluid {
    padding-left: 0px;
    padding-right: 0px;
}

/* ======== Nav ======== */

.mobile-nav {
    display: none;
}

#main-menu-mobile {

    ul {
      padding-top: 40px;
      text-align: right;

    }
    ul li {
        padding: 0;
        margin: 20px 0;
    }


 ul li a:after {
    content: "";
    display:inline-block;
    z-index: 2;
    position:absolute;
    left:0;
    top:43%;
    width:auto;
    border-top:2px solid white;
    transform: scale(0);
    transition: all 0.3s ease;

}
 ul li a:hover:after{
    width:auto;
    z-index: 2;
    transform: scale(1);
}


  .fa-facebook {
    color: #fff;
    position: absolute;
    left: 10px;
    top: 5%;
    z-index: 99999;
  }

  .fa-instagram {
    color: #fff;
    position: absolute;
    left: 40px;
    top: 5%;
    z-index: 99999;
  }
}

.mobile-menu-wrap {
  background: #000 none repeat scroll 0 0;
  width: 100%;
  position: fixed;
  z-index: 98;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
  padding-bottom: 20px;

  p {
    text-align: right;
    margin-right: 20px;
    padding-left: 70%;
    font-family: $als-regular;
    color: #fff;
  }

}

.mobile-menu-wrap.active{
  opacity: 1;
  visibility: visible;
}

header #main-menu-mobile ul {
  height: 223px;
  margin-right: 10px;
}

.fa-bars {
    cursor:pointer;
    position: absolute;
    right: 15px;
    top: 5%;
    z-index: 99;
    color: #000;
    transition: all 0.5s ease;
}

.fa-bars.active {
    color: #fff;
}

header #main-menu-mobile ul li a {
    display: block;
    font-family: $als-serif;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 14px;
    color: #fff;
}

#main-text {
    text-align: center;
    border-top: 10px solid white;
    border-bottom: 10px solid white;
    height: 285px;
    background: $light-yellow;
    display: table;
    width: 100%;
    position: relative;
    z-index: 9999;

    @media screen and (max-width: $bp-max-sm) {
        height: 255px;
    }

    .main-message {
        font-size: 21px;

        @media screen and (max-width: $bp-max-xs) {
            img {

            }
            font-size: 16px;
            padding: 20px;
        }

        @media screen and (max-width: $bp-max-sm) {
            height: 205px;
        }


        font-family: $als-regular;
        display: table-cell;
        vertical-align: middle;
        height: 285px;
        text-align:center;

        div {
            max-width: 560px;
            margin: 38px auto 0;
        }
    }

}

/*==========Slideshow==========*/


@media screen and (max-width: $bp-max-xs) {
    body.home {
        .slideshow {
            height: 200px;
        }
    }
}

@media screen and (min-width: $bp-min-sm) {
    body.home {
        .slideshow {
            height: 576px;
        }
    }

}

.slideshow-wrapper {
    position: relative;
    /*z-index: -2;*/
}

.slideshow-content {
    background-color: $light-yellow;
    background: rgba($light-yellow, 0.6);
    position: absolute;
    top: 0;
    right: 0;
    height: 20%;
    width: 500px;
    opacity: 0;
    transition: all 1s ease 1s;
}

html.no-opacity .slideshow-content {
    display: none;
}

 .slide-content-wrap {
    opacity: 0;
    transform: translateY(40px);
    transition: all 0.8s ease 1.8s;
}


html.no-opacity  .slide-content-wrap {
    display: none;
}

.slideshow-content.active  {
    transform: translateY(0);
    height: 100%;
}

.slideshow-content.active .slide-content-wrap {
    opacity: 1;
    transform: translateY(0);
}

.slideshow-content.active  {
    opacity: 1;
}


html.no-opacity .slideshow-content.active .slide-content-wrap {
    display: block;
}

html.no-opacity .slideshow-content.active {
    display: block;
}

.slideshow-buttons {
    position: absolute;
    bottom: 23px;
    left: 50%;
    margin-left: -22px;
    opacity: 0;
    transition: all 0.8s ease;
    transition-delay: 2s;
    transform: translateY(100px);

    img {
        padding: 0 1px;
        cursor: pointer;
        transition: all 0.3s ease-in-out ;
    }

    img.arrow_left:hover {
        transform: translateX(-5px);
    }

    img.arrow_right:hover {
        transform: translateX(5px);
    }
}

.slideshow-content.active .slideshow-buttons  {
    transform: translateY(0);
    opacity: 1;
}

.slide-content {
    position: absolute;
    top: 0;
    left: 50px;
    opacity: 0;
    transition: all 0.5s ease-in-out;

    &.active {
        opacity: 1;
    }

    h2 {
        color: #000;
        text-align: center;
        font-size: 48px;
        line-height: 57.6px;
        border-top: 2px solid #000;
        border-bottom: 2px solid #000;
        max-width: 408px;
        margin: 55px auto 50px;
        padding: 30px 0;
    }
}
html.no-opacity .slide-content {
    display: none;
}
html.no-opacity .slide-content.active {
    display: block;
}


@media screen and (max-width: $bp-max-xs) {

#slideshow-content-mobile {

    + .slideshow-wrapper {
        margin-top: 40%;
    }
    .slide-content-link {
        position: absolute;
        z-index: 999999;
        top: 80%;
    }

    .slide-content {
        background: #F1F1F2;
        position: absolute;
        left: auto;
        opacity: 0;
        top: 176px;
        transition: all 0.5s ease;
        width: 100%;
        text-align: center;
        padding: 5% 0 3.5%;
        z-index: 1;

        h2 {
            margin: 0 auto;
            padding: 0;
            max-width: 280px;
            font-size: 21px;
            line-height: 1.5;
            padding: 10px 0;
        }
    }

    .slide-content.active {
        opacity: 1;
    }

}

}

#slideshow-content-mobile {
  position: absolute;
  // top: -50px;
  top: 0;
  width: 100%;
}


.slide-content-link {
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-family: $als-medium;
    width: 200px;
    margin: 0 auto;
    border: 3px solid #000;
    padding: 10px 20px;
    opacity: 0;
    transition: all ease-in-out 0.3s;
}

.slide-content-link:hover {
    border: 3px solid #000;
    background: #000;
    color: #fff;
}

.slideshow-content.active .slide-content-link  {
    opacity: 1;
}

.slide-content.active{
    z-index: 99999999;
}

@keyframes aeolis {
    from { transform: scale(1);}
    to { transform: scale(1.08);}
}

.backstretch img {
    animation-name: aeolis;
    animation-duration: 10s;
    animation-timing-function: linear;
}
