.mailchimp {
  width: 100%;
  max-width: 510px;
  margin: 0 auto;
  color: white;
  font-family: $als-regular;

  input[type="email"] {
    border-radius: 0;
    font-size: 12px;
  }

  .mailchimp-subscribe {
    border: 2px solid white;
    color: white;
    background: black;

    &:hover {
      background: white;
    }
  }
}
